import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import ResourceHeader from "@components/resources/header"

import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import List from "@components/elements/list"
import Div from "@components/elements/div"
import Anchor from "@components/elements/anchor"

import { graphql } from "gatsby"
import { PARAGRAPH_SIZE } from "@components/resources/analytics-constants"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"

const AnalyticsPage = ({ data }) => {
  return (
    <Layout
      fullWidth
      title={`Social Media Analytics`}
      description={`Track all of your social media analytics in one place with a self-serve, lightweight BI tool like PowerMetrics. Supercharge your social media strategy with the data you need to make better business decisions.`}
    >
      <ResourceHeader
        shadow={false}
        name={`Starter Guide to Social Media Analytics`}
        alt={`PowerMetrics Starter Guide - Social Media Analytics`}
        subtitle={`Track all of your social media analytics in one place. With a self-serve, lightweight BI tool like PowerMetrics, you can supercharge your social media strategy with the data and insights to make better business decisions.`}
        image={data.image}
        newCta
        kpi
        ctaCategory={`Social Media Analytics`}
        links={[
          {
            text: "Resources",
            url: "/resources",
          },
          {
            text: "Analytics",
          },
          {
            text: "Social Media",
          },
        ]}
      />
      <Flex gap="8rem" margin="4rem 0">
        <Container text>
          <Heading as="h2" margin="0 0 1rem">
            Here&apos;s what we&apos;ll cover:
          </Heading>
          <List as="ol" gap="0" fontSize={PARAGRAPH_SIZE}>
            <li>
              <Anchor link="#what">What is social media analytics?</Anchor>
            </li>
            <li>
              <Anchor link="#guides">Our social media starter guides</Anchor>
            </li>
            <li>
              <Anchor link="#why">
                Why do you need a social media analytics dashboard?
              </Anchor>
            </li>
            <li>
              <Anchor link="#strategy">
                Social media strategy foundations
              </Anchor>
            </li>
          </List>
        </Container>
        <Container text>
          <Heading id="what" as="h2" margin="0 0 2rem">
            What is social media analytics?
          </Heading>
          <Paragraph fontSize={PARAGRAPH_SIZE} margin="0 0 2rem">
            Social media analytics gathers and analyzes data from social media
            channels like Twitter, LinkedIn, Facebook, and Instagram. Social
            media analytics is commonly used by digital marketers and social
            media managers to track&nbsp;
            <Anchor link="/resources/kpi-examples/social-media">
              social metrics
            </Anchor>
            &nbsp;like audience growth and conversion.
          </Paragraph>
          <Paragraph fontSize={PARAGRAPH_SIZE}>
            Your employees, your brand, and your clients are on social media.
            For digital marketers, Facebook, LinkedIn, Twitter, and Instagram
            are key pillars of a social media strategy. But maintaining a social
            media presence for your brand is only half of the responsibility.
            The other half? Measurement.
          </Paragraph>
        </Container>
        <Container text>
          <Heading id="guides" as="h2" margin="0 0 2rem">
            Check out our social media starter guides
          </Heading>
          <List as="ol" gap="0" fontSize={PARAGRAPH_SIZE}>
            <li>
              <Anchor link="/resources/analytics/social-media/facebook">
                Starter Guide to Facebook Analytics
              </Anchor>
            </li>
            <li>
              <Anchor link="/resources/analytics/social-media/linkedin">
                Starter Guide to LinkedIn Analytics
              </Anchor>
            </li>
            <li>
              <Anchor link="/resources/analytics/social-media/instagram">
                Starter Guide to Instagram Analytics
              </Anchor>
            </li>
            <li>
              <Anchor link="/resources/analytics/social-media/youtube">
                Starter Guide to YouTube Analytics
              </Anchor>
            </li>
          </List>
        </Container>
        <Container text>
          <Flex gap="2rem">
            <Heading as="h2" id="why">
              Why do you need a social media analytics dashboard?
            </Heading>
            <Paragraph fontSize={PARAGRAPH_SIZE} margin="0 0 2rem">
              If each social media platform has in-app analytics, why should you
              consider a third-party social media analytics tool like
              PowerMetrics? A social media dashboard analytics dashboard will
              help you to...
            </Paragraph>
            <Heading as="h3">Make informed decisions</Heading>
            <List margin="0 0 0 2rem" fontSize={PARAGRAPH_SIZE} gap="1rem">
              <li>What type of content do people engage with?</li>
              <li>
                What time of day do we see the highest level of engagement with
                our content?
              </li>
              <li>Are we reaching the right audience?</li>
            </List>
            <Paragraph fontSize={PARAGRAPH_SIZE} margin="0 0 2rem">
              These questions will help you analyze your current strategy and
              optimize for the future.
            </Paragraph>
            <Heading as="h3">
              Track your progress against your goals and objectives
            </Heading>
            <Paragraph fontSize={PARAGRAPH_SIZE} margin="0 0 2rem">
              Your social media strategy for each channel should be measured
              against goals and objectives. This will give you quantifiable data
              to understand what&apos;s working—or what isn&apos;t. With the
              help of a social media dashboard, you can see what&apos;s worked
              in the past and use that as a benchmark to refine your approach.
              PowerMetrics stores your data history so you can compare against
              past performance and set achievable, realistic benchmarks for
              future campaigns. Use your analytics to ground your goals with
              numbers you know you can achieve (or surpass!)
            </Paragraph>
            <Heading as="h3">Measure your success</Heading>
            <Paragraph fontSize={PARAGRAPH_SIZE}>
              Setting goals is just the starting point. You need to be able to
              measure if you achieved your goals. A social media analytics
              dashboard gives you the tools you need to understand your social
              media performance. Check in on your performance throughout the
              process and make real-time updates based on the insights
              you&apos;re provided.
            </Paragraph>
          </Flex>
        </Container>
        <Container>
          <Div padding="4rem" background="#edf3fd" borderRadius="1rem">
            <Flex gap="2rem">
              <Heading as="h2" id="strategy">
                Social media analytics is the foundation of any social media
                strategy
              </Heading>
              <Paragraph fontSize={PARAGRAPH_SIZE}>
                Gathering the right data enables you to make decisions that will
                give you a competitive edge in an increasingly-crowded social
                media world.
              </Paragraph>
              <Paragraph fontSize={PARAGRAPH_SIZE}>
                By using a social media analytics tool like PowerMetrics, not
                only can you see at-a-glance how your campaigns are performing,
                but the dashboard also:
              </Paragraph>
              <List margin="0 0 0 2rem" fontSize={PARAGRAPH_SIZE} gap="0">
                <li>
                  Updates in real-time so you can always access the most
                  up-to-date performance metrics
                </li>
                <li>
                  Combines data from all of your social media channels, all in
                  one place
                </li>
                <li>
                  Visualizes your data using charts and graphs for easy analysis
                </li>
                <li>
                  Tracks your social media history so you can understand trends
                  and growth over time
                </li>
              </List>
              <Paragraph fontSize={PARAGRAPH_SIZE}>
                Digital marketers and social media managers are busy people.
                There’s a lot of data to interpret throughout the course of an
                average work day. But with a tool like PowerMetrics, you don’t
                have to spend time logging in to your Facebook, LinkedIn,
                Twitter, or Instagram channels to extract your analytics.
                Instead, all you have to do is log in to PowerMetrics, look at
                your dashboard, and immediately understand your campaign
                performance.
              </Paragraph>
            </Flex>
          </Div>
        </Container>
        <PowerMetricsEOPCta />
      </Flex>
    </Layout>
  )
}

AnalyticsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AnalyticsPage

export const pageQuery = graphql`
  query AnalyticsPageQuery {
    image: directusFiles(
      directus_id: { eq: "21c6f6ce-f0da-4837-9b6c-f5b0c32e8b62" }
    ) {
      cdn
      id
      placeholder
      title
    }
  }
`
